.leftColumn {
    background-image: url('../../image/BackgroundNVH.svg');
    background-repeat: no-repeat;
    background-size: cover;
    background-blend-mode: darken;
}

.logoContainer {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #FFFFFF;
    border-radius: 10px;
    margin-bottom: 50px;
}

.logo {
    width: 80%;
    padding: 5px;
    height: auto;
    object-fit: cover;
}

.customInput {
    border-radius: 5px;
    border: 1px solid #d9d9d9;
}

.anticonOutlined {
    background-color: rgba(0, 155, 235, 1);
    padding: 10px;
    margin-right: 10px;
    border-radius: 50%;
    color: #FFFFFF;
}

.textStyle {
    color: rgba(0, 155, 235, 1);
}

.Link {
    color: rgba(0, 155, 235, 1);
    cursor: pointer;
    font-weight: 600;
    padding-top: 5px;
}

.Link:hover {
    text-decoration: underline;
}

.saveAndForgetContainer {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-self: center;
}

.loginButton {
    width: 100%;
    height: 50px;
    background-color: rgba(0, 155, 235, 1);
}

.loginButtonText {
    font-size: 16px;
    color: #FFFFFF;
}

@media only screen and (max-width: 576px) {
    .loginForm {
        margin-top: 40px;
        padding: 15px 20px;
    }
}