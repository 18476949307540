.mainContainer {
  width: 100%;
  display: flex;
  flex-direction: column;
  height: auto;
  gap: 10px;
  padding-inline: 30px;
  background-color: #ffffff;
}
.syncReservation {
  margin-top: 20px;
  width: 100%;
  display: flex;
  flex-direction: column;
  height: auto;
  gap: 10px;
  padding-inline: 30px;
  background-color: #ffffff;
}
