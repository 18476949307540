/** Scrollable **/
@bg-color-scroll: transparent;
// @bg-color-scroll: #aab2bf57;
@bg-color-scroll-thumb: #aab2bfbc;
@bg-color-scroll-thumb-hover: #8f99a9;
@border-radius-scroll: 8px;
@bg-color-scroll-2: #d9d9d9;
@bg-color-scroll-track: #d7d7d7;

.scroll-custom-width(@width) {
  // scrollbar-color: @bg-color-scroll @bg-color-scroll-2;
  // scrollbar-width: thin;
  &::-webkit-scrollbar {
    width: @width;
    height: @width;
  }
  &::-webkit-scrollbar-thumb {
    background-color: transparent;
  }
  &::-webkit-scrollbar-track {
    background-color: transparent;
  }
  &::-webkit-scrollbar-button {
    display: none;
    background-color: transparent;
    visibility: hidden;
  }
  &:hover {
    &::-webkit-scrollbar {
      width: @width;
      height: @width;
    }
    &::-webkit-scrollbar-thumb {
      background: @bg-color-scroll-thumb;
      border-radius: @border-radius-scroll;
      &:hover {
        background: @bg-color-scroll-thumb-hover;
      }
    }
    &::-webkit-scrollbar-track {
      // box-shadow: inset 0 0 @border-radius-scroll @bg-color-scroll-2;
      border-radius: @border-radius-scroll;
      background: @bg-color-scroll;
    }
  }
}

.custom_scrollbar {
  .scroll-custom-width(9px);
}

/** end scroll **/

/** custom antd table scrollbar */
.ant-table-wrapper .ant-table {
  scrollbar-color: unset !important;
}
.ant-table-body {
  overflow: auto !important;
}
.ant-table-body::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}

.ant-table-body::-webkit-scrollbar-track {
  box-shadow: inset 0 0 @border-radius-scroll @bg-color-scroll-2;
  border-radius: @border-radius-scroll;
  background: @bg-color-scroll;
}

.ant-table-body::-webkit-scrollbar-thumb {
  background: @bg-color-scroll-thumb;
  border-radius: @border-radius-scroll;
  &:hover {
    background: @bg-color-scroll-thumb-hover;
  }
}
.ant-pagination {
  justify-content: flex-end !important;
}
/** end custom antd table scrollbar */

.app-upload-list-item:hover {
  background-color: #d9d9d9;
}

/** global custom antd */
.ant-form-item {
  margin-bottom: 8px;
}
.ant-form-item-label {
  padding-bottom: 3px !important;
  // font-weight: 600;
}
/** end global custom antd */
