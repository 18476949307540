.buttons {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  gap: 10px;
}
.formWrapper {
  background-color: #fff;
  border-radius: 8px;
  margin-bottom: 10px;
  max-width: 100%;
  overflow-x: auto;
}
.gridContainer {
  display: grid;
  gap: 10px;
  padding: 10px;
  grid-template-columns: repeat(5, 1fr);
}
@media screen and (max-width: 670px) {
  .gridContainer {
    display: grid;
    gap: 10px;
    padding: 10px;
    grid-template-columns: 1fr;
  }
}
