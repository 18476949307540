/** Scrollable **/
.custom_scrollbar::-webkit-scrollbar {
  width: 9px;
  height: 9px;
}
.custom_scrollbar::-webkit-scrollbar-thumb {
  background-color: transparent;
}
.custom_scrollbar::-webkit-scrollbar-track {
  background-color: transparent;
}
.custom_scrollbar::-webkit-scrollbar-button {
  display: none;
  background-color: transparent;
  visibility: hidden;
}
.custom_scrollbar:hover::-webkit-scrollbar {
  width: 9px;
  height: 9px;
}
.custom_scrollbar:hover::-webkit-scrollbar-thumb {
  background: #aab2bfbc;
  border-radius: 8px;
}
.custom_scrollbar:hover::-webkit-scrollbar-thumb:hover {
  background: #8f99a9;
}
.custom_scrollbar:hover::-webkit-scrollbar-track {
  border-radius: 8px;
  background: transparent;
}
/** end scroll **/
/** custom antd table scrollbar */
.ant-table-wrapper .ant-table {
  scrollbar-color: unset !important;
}
.ant-table-body {
  overflow: auto !important;
}
.ant-table-body::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}
.ant-table-body::-webkit-scrollbar-track {
  box-shadow: inset 0 0 8px #d9d9d9;
  border-radius: 8px;
  background: transparent;
}
.ant-table-body::-webkit-scrollbar-thumb {
  background: #aab2bfbc;
  border-radius: 8px;
}
.ant-table-body::-webkit-scrollbar-thumb:hover {
  background: #8f99a9;
}
.ant-pagination {
  justify-content: flex-end !important;
}
/** end custom antd table scrollbar */
.app-upload-list-item:hover {
  background-color: #d9d9d9;
}
/** global custom antd */
.ant-form-item {
  margin-bottom: 8px;
}
.ant-form-item-label {
  padding-bottom: 3px !important;
}
/** end global custom antd */
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}
.rowSelection {
  cursor: pointer;
}
